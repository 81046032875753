$(function () {
    initNav();
    initSlick();
    initAanbodCarousel();
    initUspsCarousel();
    initDropdown();
    initMatchHeight();
    initObject();
    initScroll();
    initTimeline();
    initContact();
    initFaq();
    initFakeInput();
});

$(window).on('load', function() {
    orientObjectSlider();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        $nav.children('ul').attr('style', false);
        // initObject();
        orientObjectSlider();
    }, 200));
});

function initNav() {
    $btn_navigation = $('header .nav-toggle');
    $nav = $('header nav');

    $btn_navigation.click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 980) {
            if ($nav.css('visibility') === 'hidden' || $nav.is(':hidden')) {
                $nav.slideDown();
                $('body').addClass('nav-open');
            } else {
                $nav.slideUp();
                $('body').removeClass('nav-open');
            }
        }
    });

    if ($nav.find('ul').length > 0) {
        $li = $nav.find('li');

        $li.has('ul').children('a').click(function (e) {

            $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle');

                    if ($(window).width() < 1024) {
                        $(ul).slideUp();
                    }
                }
            });

            if ($(this).next('ul').css('visibility') === 'hidden' || $(this).next('ul').is(':hidden')) {
                e.preventDefault();

                if ($(window).width() < 1024) {
                    $(this).next('ul').slideDown();
                }
                $(this).children('span').addClass('toggle');
            }
        });
    }

    $(window).scroll(function () {
        if ($(this).scrollTop() > 30) {
            $('.nav_wrapper').addClass('fixed');
        } else {
            $('.nav_wrapper').removeClass('fixed');
        }
    });
}

function initSlick() {
    if ($('#header_carousel').length > 0) {
        $('#header_carousel').slick({
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 6000,
            speed: 1500,
            slide: '.desktop',
            cssEase: 'ease-out',
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slide: '.mobile'
                    }
                }
            ]
        });
    }
}

function initAanbodCarousel() {
    if ($('#aanbod_carousel').length > 0) {
        $('#aanbod_carousel').slick({
            arrows: true,
            slidesToShow: 2,
            prevArrow: $('#aanbod_wrapper .icon-arrow-left'),
            nextArrow: $('#aanbod_wrapper .icon-arrow-right'),
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
}

function initUspsCarousel() {
    if ($('#usps').length > 0) {
        $('#usps').slick({
            arrows: false,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
}

function initDropdown() {
    $('.dropdown').each(function (i, el) {
        $(el).on('click', function () {
            $(el).find('ul').fadeToggle(150);

            $(el).find('a').on('click', function (e) {
                $(el).find('.value').text($(this).text());
            });
        });
    });
}

function initMatchHeight() {
    if ($('.matchheight').length > 0) {
        $('.matchheight').matchHeight();
    }
}

function initObject() {
    if ($('#object').length > 0) {
        // let top = Math.round($('#wrapper_omschrijving').position().top);
        // let floater_top = top + 70;
        // $(window).scroll(function () {
        //     clearTimeout($.data(this, 'scrollTimer'));
        //
        //     newtop = Math.round((top - $(this).scrollTop()) * -1);
        //     maxtop = Math.round($('#wrapper_contact').position().top + $('#wrapper_contact').outerHeight() - $('.floater').outerHeight());
        //
        //     if ($(this).scrollTop() > top) {
        //         if (maxtop > $(this).scrollTop()) {
        //             $.data(this, 'scrollTimer', setTimeout(function () {
        //                 $('.floater').css('top', Math.min(newtop, (maxtop - floater_top)));
        //             }, 30));
        //         } else {
        //             $.data(this, 'scrollTimer', setTimeout(function () {
        //                 $('.floater').css('top', (maxtop - floater_top));
        //             }, 30));
        //         }
        //     } else {
        //         $('.floater').css('top', '');
        //     }
        // });

        // Fuck foto slider
        $('#photoslider .slider').slick({
            mobileFirst: true,
            prevArrow: '<i class="prev icon icon-chevron-left"></i>',
            nextArrow: '<i class="next icon icon-chevron-right"></i>',
        });

        $(document).on('keydown', function(e) {
            if (e.keyCode === 37) { // Left arrow
                $('#photoslider .slider').slick('slickPrev');
            }
            if (e.keyCode === 39) { // Right arrow
                $('#photoslider .slider').slick('slickNext');
            }
        });

        $('figure.fotoheader').on('click', function() {
            $('#photoslider .loader').css({
                display: 'block'
            });
            
            $('#photoslider').fadeIn('fast');
            $('#photoslider .slider').slick('slickGoTo', 0);

            setTimeout(function() {
                $('#photoslider .loader').fadeOut('fast');
            }, 500);
        });

        $('#fotos .foto').each(function() {
            $(this).on('click', function() {
                $('#photoslider .loader').css({
                    display: 'block'
                });
                
                $('#photoslider').fadeIn('fast');
                $('#photoslider .slider').slick('slickGoTo', $(this).data('slide'));

                setTimeout(function() {
                    $('#photoslider .loader').fadeOut('fast');
                }, 500);
            });
        });

        $('#photoslider .close').on('click', function() {
            $('#photoslider').fadeOut('fast');
        });

        // $('#floorplans .foto').each(function () {
        //     $(this).on('click', function () {
        //         $('#floorplanslider .loader').css({
        //             display: 'block'
        //         });
        //
        //         $('#floorplanslider').fadeIn('fast');
        //         $('#floorplanslider .slider').slick('slickGoTo', $(this).data('slide'));
        //
        //         setTimeout(function() {
        //             $('#floorplanslider .loader').fadeOut('fast');
        //         }, 500);
        //     });
        // });
        //
        // $('#floorplanslider .close').on('click', function() {
        //     $('#floorplanslider').fadeOut('fast');
        // });

        // Button
        $('#omschrijving + a').on('click', function (e) {
            e.preventDefault();

            $('#omschrijving').addClass('show');
            $(this).hide();
        });

        // Lightbox
        if ($('.lightbox').length > 0) {
            $('.lightbox').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        }

        // initObjectPano();

        // Gallery icon
        $('.icon-zoom-in').on('click', function (e) {
            e.preventDefault();
            $('.lightbox').first().click();
        });
    }    
}

function orientObjectSlider() {
    $('#photoslider .slider .photo img').each(function () {
        var photo = $(this).clone().appendTo('body');
        photo.remove();

        if ($(window).outerWidth() > $(window).outerHeight()) {
            $(this).css({
                height: $(window).outerHeight(),
                width: 'auto'
            });
        } else {
            $(this).css({
                height: 'auto',
                width: $(window).outerWidth()
            });
        }
    });
}

function initObjectPano() {
    $('[id^=pano-]').each(function (index) {
        id = $(this).attr('data-id');
        data = {
            js: '/js/pano/pano.js',
            swf: '/js/pano/pano.swf',
            // xml: window.location.origin + window.location.pathname + '/pano/' + id + '.xml',
            target: 'pano-' + id,
            html5: 'prefer',
            passQueryParameters: true
        };
        embedpano(data);
    });
}

function initScroll() {
    if ($('.icon-scroll').length) {
        $('.icon-scroll').on('click', function (e) {
            e.preventDefault();
            $('html,body').stop().animate({
                scrollTop: $("#homepage").offset().top - 70
            }, 300, 'linear');
        });
    }
}

function initTimeline() {
    if ($('#overons').length) {
        $('.timeline .line').each(function () {
            var height = $(this).find('.text span p').outerHeight();
            $(this).find('.text span').css('height', height + 40);

            if ($(window).width() >= 1024) {
                $(this).find('.text').css('height', $(this).outerHeight());
                $(this).find('.image').css('height', $(this).outerHeight());
            } else {
                $(this).find('.text').css('height', height + 40);
                $(this).find('.image').css('height', '120px');
            }
        });
    }
}

function initContact() {
    if ($('#contact').length > 0) {
        var latlng = new google.maps.LatLng($('#maps').data('lat'), $('#maps').data('lng'));

        map = new google.maps.Map(document.getElementById("maps"), {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng,
            scrollwheel: false
        });

        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: '/img/icon-map.png'
        });
    }
}


function initFaq() {
    if ($('#faq').length > 0) {
        if ($('.faq_wrapper h3').length > 0) {

            $('.faq_wrapper h3').on('click', function (e) {
                if ($(this).next('ul').is(':visible')) {
                    $(this).next('ul').hide();
                } else {
                    $('.questions').hide();
                    $(this).next('ul').show();

                    $('html, body').animate({
                        scrollTop: $(this).offset().top - 100
                    }, 1000);
                }
            });
        } else {
            $('.questions').show();
        }

        $('.question').on('click', function () {
            $(this).siblings().removeClass('show');
            $(this).toggleClass('show');
        });
    }
}

function initFakeInput() {
    if ($('.fake_input')) {
        $('.fake_input').each(function (i, el) {
            var input = $(el).find('input[type=hidden]');
            var content = $(el).find('.content');

            $(el).on('click', function () {
                var ul = $(this).next();
                ul.fadeToggle(100);

                ul.find('li').on('click', function () {
                    input.val($(this).data('value'));
                    content.html($(this).text());
                    ul.fadeOut(100);
                });
            });

            if ($(el).is(':visible')) {
                $(document).click(function(e) {
                    if ($(e.target).closest(el).length === 0) {
                        $(el).next().fadeOut(100);
                    }
                });
            }
        });
    }
}